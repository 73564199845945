import { AsType } from '../lib/tsUtils';

export const referralActionTypes = {
  SET_REVIEW_TAB: 'SET_REVIEW_TAB',
  SET_QUESTIONNAIRE_DATA: 'SET_QUESTIONNAIRE_DATA',
  SET_TEST_DATA: 'SET_TEST_DATA',
  SET_MEDICATION_DATA: 'SET_MEDICATION_DATA',
  SET_HQ_DATA: 'SET_HQ_DATA',
  SET_INCOMPLETE_HQS: 'SET_INCOMPLETE_HQS',
  SET_FILE_NOTES: 'SET_FILE_NOTES',
  SET_PATIENT_ALLERGIES: 'SET_PATIENT_ALLERGIES',
  SET_PREVIOUS_SURGERIES: 'SET_PREVIOUS_SURGERIES',
  SET_REFERRAL_LIST_FILTERS: 'SET_REFERRAL_LIST_FILTERS',
  SET_REFERRAL_PREDICTION_LIST_FILTERS: 'SET_REFERRAL_PREDICTION_LIST_FILTERS',
  SET_REFERRAL_LIST_RIBBON_FILTERS: 'SET_REFERRAL_LIST_RIBBON_FILTERS',
  SET_REFERRAL_LIST_TAB: 'SET_REFERRAL_LIST_TAB',
  SET_REFERRAL_ALERTS: 'SET_REFERRAL_ALERTS',
  SET_REFERRAL_RISKS: 'SET_REFERRAL_RISKS',
  SET_REFERRAL_PREDICTIONS: 'SET_REFERRAL_PREDICTIONS',
  SET_DISCHARGE_DESTINATION: 'SET_DISCHARGE_DESTINATION',
  SET_SURGERY_DATE: 'SET_SURGERY_DATE',
  SEND_GP_REFERRAL_LIST_REQUEST: 'SEND_GP_REFERRAL_LIST_REQUEST',
  SET_PENDING_APPOINTMENT: 'SET_PENDING_APPOINTMENT',
  SET_PENDING_CANCELLATION_REASON: 'SET_PENDING_CANCELLATION_REASON',
  SET_ADVICE_SENT: 'SET_ADVICE_SENT',
  SET_SCHEDULE_ADVICE: 'SET_SCHEDULE_ADVICE',
  SET_ADVICE: 'SET_ADVICE',
  SET_BOWEL_PREP_OPTION: 'SET_BOWEL_PREP_OPTION',
  SET_REFERRAL_DATA: 'SET_REFERRAL_DATA',
} as const;

export type ReferralActionType = AsType<typeof referralActionTypes>;

export const alertActionTypes = {
  SET_ALERT: 'SET_ALERT',
  HIDE_ALERT: 'HIDE_ALERT',
} as const;

export type AlertActionType = AsType<typeof alertActionTypes>;

export const localeActionTypes = {
  SET_IS_RTL: 'SET_IS_RTL',
  SET_LOCALE: 'SET_LOCALE',
} as const;

export type LocaleActionType = AsType<typeof localeActionTypes>;

export type ActionType = ReferralActionType | AlertActionType | LocaleActionType;

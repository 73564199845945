import PatientQuestionStatus from '../lib/status/patientQuestionStatus';
import { Nullable } from '../lib/tsUtils';
import { ClinicianStatus } from '../lib/types/clinicianStatus';
import Referral, {
  ReferralKinData,
  ReferralPatientData,
  ReferralProcedureData,
} from '../lib/types/Referral';
import { ReferralStatus } from '../lib/types/referralStatus';
import { TestStatus } from '../lib/types/testStatus';

const initialPatinetData: ReferralPatientData = {
  patientUuid: '',
  patientGender: null,
  patientTitle: null,
  patientFirstName: null,
  patientLastName: null,
  dateOfBirth: null,
  patientEmailAddress: null,
  patientMobileNumber: null,
  ur: '',
  patientImagePresent: false,
};

const initialProcedureData: Nullable<ReferralProcedureData> = {
  scheduledProcedure: null,
  procedureCategory: null,
  surgeryLocation: null,
  surgicalUnitName: null,
  destinationName: null,
};

const initialKinData: Nullable<ReferralKinData> = {
  kinCorrespondence: null,
  kinName: null,
  kinEmailAddress: null,
  kinMobileNumber: null,
};

const initialReferral: Referral = {
  gpReferralId: 0,
  gpReferralUuid: '',
  orgId: 0,
  patientId: 0,
  referralReviewData: null,
  createdAt: new Date(),
  procedureId: null,
  surgicalUnitId: null,
  surgeonId: null,
  anaesthetistId: null,
  surgeryLocationId: null,
  gpName: '',
  gpEmailAddress: null,
  gpMobileNumber: null,
  surgeryDate: null,
  kinId: null,
  currentState: ReferralStatus.WaitingList,
  lastTransitionDate: null,
  isDeleted: false,
  destinationId: null,
  sendReminders: false,
  remindersCancelReason: null,
  testStatus: TestStatus.None,
  drReviewStatus: ClinicianStatus.None,
  questionStatus: PatientQuestionStatus.None,
  adviceScheduled: false,
  adviceSent: false,
  advice: null,
  triageCategory: null,
  stayLength: null,
  bowelPrep: null,
  ...initialPatinetData,
  ...initialProcedureData,
  ...initialKinData,
};

const initialExtendedReferral = {
  surgeonName: undefined,
  surgeonEmailAddress: undefined,
  anaesthetistName: undefined,
  anaesthetistEmailAddress: undefined,
  ...initialReferral,
};

export default initialExtendedReferral;
